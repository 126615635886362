import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import './char-guide.scss';
import { NikkeCharacter } from '../../../modules/nikke/common/components/nikke-character';
import { Alert, Col, Row } from 'react-bootstrap';

const NikkeGuidesTove: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page char-guide'} game="nikke">
      <ul className="breadcrumb">
        <li>
          <Link to="/nikke/">NIKKE</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/nikke/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Tove guide & review</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/nikke/categories/category_tove.jpg"
            alt="Wishlist"
          />
        </div>
        <div className="page-details">
          <h1>Tove guide & review</h1>
          <h2>A guide & review for Tove in NIKKE: Goddess of Victory.</h2>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <SectionHeader title="Banner" />
        <StaticImage
          src="../../../images/nikke/generic/banner_tove.jpg"
          alt="Tia"
        />
        <p>
          <strong>Tove</strong> banner will be available from{' '}
          <strong>11/23 05:00 ~ 12/07 04:59 (UTC+9).</strong>
        </p>
        <SectionHeader title="Foreword" />
        <div className="employee-container for-nikke">
          <NikkeCharacter mode="icon" slug="tove" enablePopover />
        </div>
        <p>
          If you want someone who is not afraid to sacrifice herself to help
          others, but has extremely poor sled making skills, Tove is the nikke
          for you! The Survivor who wouldn't survive Alone in the cold at all,
          has come to nikke in order to help you buff your Shotguns better than
          anyone else can! Her skills are a bit unique, as she has an RNG
          dependant skill 1 activation, but the buffs themselves are very much
          everything your shotgun needs! Her skill 2 will be mainly buffing the
          Attack Speed of your shotguns, and her Burst is a chonky Attack Buff
          that will make your Shotguns hit hard against anyone they're up
          against! However be wary, these buffs rely on her skill 1 to be
          stacked to give the best output!
        </p>
        <SectionHeader title="Kit Analysis" />
        <p>
          Tove is the currently best unit for supporting SG teams. Not only does
          she provide SG units a higher ATK buff compared to Liter, she also
          increases max ammo and overall damage dealt via ATK SPD. Her kit is
          designed to support SGs and only SGs (her buff is still usable outside
          SG, but it's very cope).
        </p>
        <p>
          Tove possesses an intriguing skillset that is somewhat reliant on RNG,
          which means to achieve the highest damage with her, you may need to
          repeat your runs. This is because most of her supportive skills are
          locked behind 3 Stacks of Temporary Modification, whose uptime can
          vary sharply between runs. If Snow White drives you insane, Tove will
          too. Thankfully, this RNG can be attenuated slightly with the help of
          OL. However, before we delve into the technicalities of it, let's
          briefly go through her skills one by one.
        </p>
        <h6>Skill 1</h6>
        <p>
          This skill grants her the ability to randomly trigger Emergency
          Bullets at a 5% chance. When triggered, she reloads a certain amount
          of ammo and increases/refreshes the stack of Temporary Modification
          (abbreviated as TM in this guide), which grants increased Max Ammo to
          all allies for 5s. The reload helps her stack accumulation become more
          consistent, whereas the Max Ammo is like every other Max Ammo
          buff—extremely useful for units who have a low base ammo count like SG
          (except SAnis), SR, and RL. She also boosts the team's CRIT DMG, but
          the value is low.
        </p>
        <p>
          Tove wields an Assault Rifle, which shoots at a firerate of 12/s (60
          FPS; not FPS-friendly, by the way). Therefore, within 5s and without
          reloading, she blasts a maximum of 60 shots. When firing a shot, her
          chance of not gaining a stack is 95%. Using those variables, we can
          calculate that the chance of Tove not increasing/refreshing Temporary
          Modification when continuously shooting for 5s to be ±4.6%. If she
          reloads within this 5s window, it will drop to around ~7-9%, depending
          if she uses Resilience or Bastion.
        </p>
        <p>
          If Tove fails to accrue 1 stack of TM within 5s of the previous
          trigger, it will reset back to zero! This will result in a heavy DPS
          loss as it will deactivate or weaken her buffs, especially the ATK SPD
          in her S2 and the ATK in her Burst. She will need to trigger it again
          3 times in a row. If you want to know how common this is, the
          estimated uptime of a base Tove is (7.7%, 7.7%, 7.7%, 77%) and an
          infinite ammo Tove is (5%, 5%, 5%, 85%), denoting (Stack 0, 1, 2, 3)
          respectively. Thank you to Titan for the simulation; here is the{' '}
          <a
            href="https://discord.com/channels/968096627002851379/1176712613556658196/1176929665391280309"
            target="_blank"
            rel="noopener noreferrer"
          >
            original post
          </a>{' '}
          in Nikke Community Discord.
        </p>
        <h6>Skill 2</h6>
        <p>
          When TM is fully stacked, Tove passively grants all allies bonus
          critical rate. She also grants additional ATK SPD to all allied SG. As
          you can see, the ATK SPD is what makes this skill worth upgrading. It
          functions as a separate total damage multiplier unlike most buffs in
          this game. According to the frame calculation formula, some levels
          don't actually provide a real attack speed increase. Visit Skill
          Investments for more detail.
        </p>
        <Alert variant="primary">
          <p>
            As stated in the description of Skill 1, resetting TM stacks will
            deactivate this buff. You will see that your SG's firerate fluctuate
            throughout the battle.
          </p>
        </Alert>
        <h6>Burst</h6>
        <p>
          Her Burst Skill grants an ATK buff whose effectiveness scales up with
          the possessed stacks of TM. The ATK buff stack mirrors TM's stack and
          is determined on activation; it does not dynamically update. It is
          guaranteed to have 1 stack on activation, even if TM has 0 stack.
          Therefore, you will always want to wait until Tove has 3 stacks of TM
          before activating her burst. Whether she loses TM stacks later on, it
          will not impact the given ATK buff. It still deactivates her Skill 2,
          regardless.
        </p>
        <p>
          Thanks to its big numbers, this skill is what makes Tove simply
          irreplaceable when it comes to supporting SG units. It grants nearly
          80% caster's ATK buff to all SG units in the team for 10s. If we
          convert this into Attacker stats, it would still amount to around
          66.67%, which is superior to Liter's Burst in multiple ways. The only
          drawback is that, for this to be optimal, you are forced to invest in
          her copies and gear as her base ATK stat matters.
        </p>
        <div className="fuse-ad-placeholder" data-fuse="22844297247"></div>
        <SectionHeader title="Usage" />
        <p>
          Tove and the SGs are unlikely to be used in Campaign or Tower due to
          range restrictions. That said, below are some game modes they might
          excel in:
        </p>
        <h6>UNION RAID</h6>
        <p>
          If you have no better units for Union Raid, then Tove and the SGs can
          be deployed. Their damage is by no means outstanding, but it is still
          better than assembling a random team and praying that it will work.
          Avoid picking bosses that are too thin as SG requires hitting most if
          not all pellets to be remotely usable.
        </p>
        <h6>SOLO RAID</h6>
        <p>
          Tove and the SGs will most likely make it to the Top 5 Teams in Solo
          Raid, but as Team 4/5. This might change in the future if stronger
          SG-related units are released. Even then, this team is still terrible
          at dealing with thin bosses. They will be of higher value against
          close-range bosses, no-core bosses, or bosses with a large hitbox.
          Examples include Gravedigger, Modernia (when not teleporting), and so
          on.
        </p>
        <h6>PVP</h6>
        <p>
          Tove will probably never see the light of day in PvP meta, but if you
          have no one else to fill for the B1 spot, you can use Tove to help
          empower your SG units. However, she is handicapped in PvP. The 30 FPS
          limit lowers her fire rate to just 10/s, resulting in an around 50%
          probability of her accruing 3 stacks of TM within 5s. This means RNG
          may affect the outcome of a round. In the worst case, Tove does not
          gain any stack and becomes a sitting duck. On a totally different
          note, you can also pair Tove with RL and SR units, but in this case
          you only want to benefit from the Max Ammo buff that she provides.
          It's quite minuscule, but it can be the decider of the match
          (especially if you use Alice with a low base ammo count).
        </p>
        <SectionHeader title="Investments" />
        <h5>Gear Investments</h5>
        <p>
          Like Naga, Tove's ATK buffs scale up with her base stats. Therefore,
          you should definitely OL and raise her gear to level 5, especially
          Head and Gloves. Body gives less stats, so you can OL and leave it at
          0~3 to save materials. Her Boots do not provide ATK stat increase, but
          Tove benefits from Max Ammo OL, so you decide whether that would be a
          good investment for you. It is obviously the least cost-effective but
          is required for minmaxing.
        </p>
        <p>
          As stated before, only aim for Max Ammo OL. You will need more than
          ~250%+ Max Ammo buff in total, alongside Bastion level 7+, to allow
          Tove to continually firing for 180 seconds straight. You still need
          some luck to achieve this, as it is also dependent on triggering
          Emergency Bullet (Skill 1). You might ask: what if I didn't have that
          much Max Ammo? It's fine. Every little bit helps Tove maintain her
          stacks easier. You will definitely feel the QoL improvements.
        </p>
        <Alert variant="primary">
          <p>
            Currently, Tove is not a member of the top three meta teams and is
            used with weaker units. This means investing in her is rather a low
            priority goal, or in other words she is a late-game unit for players
            with resources to spare. Hence, unless you don't have anyone else to
            build, feel free to upgrade Tove. Otherwise, prioritize other meta
            units first. Note that this advice might change with new unit
            releases.
          </p>
        </Alert>
        <h5>Skill Investments</h5>
        <ul>
          <li>Skill 1: Minimum 9</li>
          <ul>
            <li>
              The justification is that level 9 is the breakpoint for the
              highest probability of activating Emergency Bullet, which is vital
              to sustaining Temporary Modification stacks.
            </li>
          </ul>
          <li>Skill 2: 4/7/10</li>
          <ul>
            <li>
              You can expect a real attack speed increase at level 2/4/7/10,
              each milestone taking 1 less frame per attack overall.
            </li>
          </ul>
          <li>Burst: Recommended 10</li>
          <ul>
            <li>
              There are no breakpoints. Like for Liter's and Naga's Burst, we
              recommend raising it to 10 because it offers a generous amount of
              ATK buff. ATK buff is just too precious. However, you can still
              use her even if her Burst Skill is below 10.
            </li>
          </ul>
        </ul>
        <Alert variant="primary">
          <p>
            Start with 9/4/4, then progress to 9/4/10 slowly. Feel free to
            upgrade Skill 2 to level 6 or 8, or wait until extensive testing has
            been done.
          </p>
        </Alert>
        <h5>Cube Investments</h5>
        <StaticImage
          src="../../../images/nikke/generic/cube_3_sm.png"
          alt="Marciana"
        />
        <p>
          Base Tove (no Max Ammo) reloads every 6s. This means she will be
          hitting minimum probability in almost every 5s window. Because of
          this, we recommend Resilience, but Bastion 7+ is also not a bad pick
          as it will make accruing 3 stacks within 1 clip easier. If she has
          high base ammo, you should get Bastion instead, as maintaining stacks
          is much easier if Tove keeps firing. It also synergizes well with her
          Emergency Bullet (Skill 1).
        </p>
        <div className="fuse-ad-placeholder" data-fuse="22844297247"></div>
        <SectionHeader title="Team Compositions" />
        <p>
          Specialized in buffing SG units, Tove's teams revolve around pairing
          her with the strongest SG characters (excluding SAnis due to their
          anti-synergy). Note that, for the team examples, we do not include
          Noir as she most likely stands by Blanc. If you have an unused Noir,
          however, feel free to use her in place of Drake, Sugar, or Guilty. See
          if she helps improve your damage!
        </p>
        <p>
          <strong>Her rotation is as follows:</strong> Wait till Temporary
          Modification reaches stack 3 (indicated by a number next to the blue
          bullet), then activate her burst. Cycle through B2 and B3 normally,
          then repeat. To restate, the strength of ATK buff is determined when
          Burst Skill is activated and does not dynamically update.
        </p>
        <h5>Team #1</h5>
        <div className="nikke-team-showcase">
          <div className="team-slot">
            <h5 className="burst-1">Burst 1</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="tove" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-2">Burst 2</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="dolla" enablePopover />
              <NikkeCharacter
                mode="icon"
                slug="aqua-marine-helm"
                enablePopover
              />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="sugar" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="drake" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-flex">Flex</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="marciana" enablePopover />
              <NikkeCharacter mode="icon" slug="rapunzel" enablePopover />
              <NikkeCharacter mode="icon" slug="helm" enablePopover />
            </div>
          </div>
        </div>
        <p>
          Tove's most universal team that may prove useful in various content
          where SG is usable. This includes close-range bosses, bosses with no
          core, and bosses with a giant hitbox. This team is easy to use and may
          be her best team so far until new SG units are released. Among the
          healers, Helm and Marciana are the best because they synergize with
          Tove and SG units well. Folkwang can be used in Solo Raid as a cope
          unit, and Poli may boost overall damage. However, she is more of
          shielding (and damage sharing) rather than healing.
        </p>
        <h5>Team #2</h5>
        <div className="nikke-team-showcase">
          <div className="team-slot">
            <h5 className="burst-1">Burst 1</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="tove" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-2">Burst 2</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="dolla" enablePopover />
              <NikkeCharacter
                mode="icon"
                slug="aqua-marine-helm"
                enablePopover
              />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="sugar" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-2">Burst 2</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="guilty" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="drake" enablePopover />
              <NikkeCharacter mode="icon" slug="helm" enablePopover />
            </div>
          </div>
        </div>
        <p>
          The Guilty variant requires a B3 healer to complete the burst
          rotation, but if the stage does not need one (like Gravedigger), feel
          free to swap it out for another DPS! Drake is a good start or, if you
          feel fancy, pick other SG that has elemental advantage (still need
          investments to back it up).
        </p>
        <h5>Team #3</h5>
        <div className="nikke-team-showcase">
          <div className="team-slot">
            <h5 className="burst-1">Burst 1</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="tove" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="sugar" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-2">Burst 2</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="guilty" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="drake" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-flex">Flex</h5>
            <div className="placeholder-box">Flex</div>
          </div>
        </div>
        <p>
          No CDR? No worries. Bring as many SG units as possible to benefit from
          Tove's buffs! The FLEX unit can be a healer or another DPS; it's up to
          you. This team is a bit of a meme, but a base Tove may reset her
          stacks too often that you might wait for too long between every
          rotation, devaluing the benefits of CDR. Nevertheless, in an ideal
          world, you want to run a CDR and RNG her stacks.
        </p>
        <hr />
        <p>
          Why Sugar, Drake, and Guilty? Because they are just superior. For
          example, Sugar and Drake are versatile choices who offer great damage
          potential and teamwide buffs. Guilty is also fantastic at delivering
          constant damage without much reliance on support units.{' '}
        </p>
        <p>
          That being said, sometimes, you don't need Sugar, Guilty, or Drake if
          you can abuse elemental damage. Other SG units like Maiden, Neve,
          Isabel, or Viper can be used as alternatives and may outdamage the
          favorite picks if they have elemental damage. To achieve this,
          however, you will need tons of luck and be willing to invest in
          non-meta units. For instance, against a Water boss, bringing Maiden
          and/or Isabel with elemental damage OL may provide more damage than
          bringing Drake. Even Limit Breaks may shift the ranks. Monitor your
          resources, allocate them smartly, and decide how you want your Tove's
          team to be built. This will vary between players.{' '}
        </p>
        <SectionHeader title="Should You Pull" />
        <p>
          Tove may be a sleeper unit. She is not particularly valuable right now
          but may enter the meta in the future, like Mast when she was first
          released. For futureproofing, consider getting 1 copy. Otherwise, you
          will have to gamble for her in Standard Banner by wishlisting her.
        </p>
        <div className="fuse-ad-placeholder" data-fuse="22844297247"></div>
        <SectionHeader title="Pros & Cons" />
        <Row className="pros-cons">
          <Col sm="12" md="6">
            <div className="box pros">
              <h5>Pros</h5>
              <hr />
              <div className="raw list">
                <ul>
                  <li>Currently the best support unit for SGs</li>
                  <li>
                    Likely a sleeper unit; may see more use in the future as new
                    units are released
                  </li>
                  <li>
                    Considered to be a potential unit for the Top 5 Teams of
                    Solo Raid
                  </li>
                  <li>
                    Grants one of the highest and longest ATK buffs for a 20s
                    Burst I unit
                  </li>
                  <li>
                    ATK buff is based on caster's stats, so it works well for
                    defenders/supporters as it does for attackers
                  </li>
                  <li>
                    A huge max ammo buff with high uptime, in spite of RNG
                  </li>
                  <li>
                    Attack speed is a rare buff, so its benefits are unlikely to
                    be diluted
                  </li>
                  <li>
                    The perk Emergency Bullets allows unlimited shooting with
                    high Max Ammo OL
                  </li>
                  <li>A living stove</li>
                </ul>
              </div>
            </div>
          </Col>
          <Col sm="12" md="6">
            <div className="box cons">
              <h5>Cons</h5>
              <hr />
              <div className="raw list">
                <ul>
                  <li>
                    Extremely RNG based; can run without retrying but expect
                    varying total damage
                  </li>
                  <li>
                    Buffs activate and deactivate throughout the battle a lot,
                    and bad timings slow down rotations
                  </li>
                  <li>
                    RNG is dependent on OL, increasing overhead; it is also
                    affected by FPS
                  </li>
                  <li>
                    ATK buff is based on caster's stats, thus slightly weaker
                    for attackers, and requires gear investment
                  </li>
                  <li>
                    Most buffs are designed only for SGs, and to make it worse,
                    SG has a limited range and large spread, making them awful
                    in most PvE content
                  </li>
                  <li>
                    Max Ammo conflicts with SAnis' last bullet gimmick, so they
                    can't be paired together without sacrificing some of her
                    kits
                  </li>
                  <li>
                    Steep skill investment curve; Skill 1 needs to be at least
                    level 9, or she is simply sabotaged
                  </li>
                  <li>
                    An expensive late-game unit for maximizing damage in Solo
                    Raid; does not offer much in return for now
                  </li>
                </ul>
              </div>
            </div>
          </Col>
        </Row>
        <SectionHeader title="Final ratings" />
        <div className="ratings-container">
          <div className="ratings-box b">
            <h6>B</h6>
            <p>Story (Early)</p>
          </div>
          <div className="ratings-box b">
            <h6>B</h6>
            <p>Story (Late)</p>
          </div>
          <div className="ratings-box s">
            <h6>S</h6>
            <p>Boss (Solo)</p>
          </div>
          <div className="ratings-box a">
            <h6>A</h6>
            <p>Boss (Adds)</p>
          </div>
          <div className="ratings-box b">
            <h6>B</h6>
            <p>PVP</p>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default NikkeGuidesTove;

export const Head: React.FC = () => (
  <Seo
    title="Tove guide & review | NIKKE | Prydwen Institute"
    description="A guide & review for Tove in NIKKE: Goddess of Victory."
    game="nikke"
  />
);
